import React, {
  FC,
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import BulkPayoutLayout from "../../components/BulkPayoutLayout/BulkPayoutLayout";
import { useNavigate, useLocation } from "react-router-dom";
import Table from "../../components/Table/Table";
import { PlusOutlined } from "@ant-design/icons";
import CustomInputDropdown from "../../components/UIKits/CustomInputDropdown/CustomInputDropdown";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import { manualBulkPayoutSchema } from "../../validation-schemas/manual-bulk.schema";
import { Upload, Button } from "antd";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { VendorDetailType } from "../Payouts/types";
import { searchVendor } from "../../services/vendor";
import { useMutation, useQuery } from "react-query";
import CustomInput from "../../components/UIKits/CustomInput/CustomInput";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/checkmark.svg";
import { ReactComponent as GradientBackground } from "../../assets/icons/gradientbackground.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { ReactComponent as ConnectionErrorIcon } from "../../assets/icons/connection-error.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/Bill Cross.svg";
import { Modal } from "antd";
import PrimaryButton from "../../components/Button/PrimaryButton";
import OutlineButton from "../../components/OutlineButton/OutlineButton";
import {
  getTaxes,
  intiateBulkPayout,
  getTaxesLogic,
} from "../../services/payout-request";
import { TaxItemType } from "../../components/PayoutCreationDrawer/types";
import { formatCurrency } from "../../utils/helper";
import { Checkbox, GetProp } from "antd";
import "./BulkPayoutManualEntry.scss";
import { ManualBulkPayoutRowData, chargeDataPayloadType } from "./types";
import StatusContainer from "../../components/StatusConatiner/StatusContainer";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import DynamicTable from "../../components/DynamicTable/DynamicTable";

interface VendorSelectValue {
  value: string;
  label: string;
}

const BulkPayoutManualEntry = () => {
  const { payeeType } = useLocation().state || {};
  const navigate = useNavigate();
  const [showInitiateModal, setShowInitiateModal] = useState(false);
  const [closePayout, setClosePayout] = useState(false);
  const [taxList, setTaxList] = useState([]);
  const [totalTaxDeduction, setTotalTaxDeduction] = useState(0);
  const [totalAmountPayable, setTotalAmountPayable] = useState(0);
  const [selectedTaxes, setSelectedTaxes] = useState<number[]>([]);
  const [payload, setPayload] = useState({});
  const [status, setStatus] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const statuses = ["payoutSuccessful", "payoutFailed", "connectionError"];
  const [duplicateIndex, setDuplicateIndex] = useState<number | null>(null);

  const getInitialPayeeType = () => {
    if (payeeType === "Vendor") return 1;
    if (payeeType === "Employee") return 2;
    return null;
  };

  const [filters, setFilter] = useState({
    Page: 1,
    PageSize: 4000,
    Status: 1,
    PayeeType: getInitialPayeeType(),
  });
  const validationSchema = manualBulkPayoutSchema(payeeType);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tableData: [
        {
          payeeId: { value: "" },
          amountPayable: 0,
          vat: false,
          whtGeneralContract: false,
          whtConsultancyService: false,
          stampDuty: false,
          reason: "",
          supportingDocument: "",
          amount: 0,
        },
      ],
    },
  });

  const generalTableData = watch("tableData");
  // Total values
  const totalExcludingTax =
    generalTableData?.reduce(
      (sum, item) => sum + parseFloat(item.amount?.toString()),
      0
    ) || 0;
  const generalTotalamountPayable =
    generalTableData?.reduce((sum, item) => sum + item.amountPayable, 0) || 0;

  // Fetch tax list

  const { isLoading: isRetrievingTaxList, data: taxData } = useQuery(
    "taxList",
    getTaxesLogic,
    {
      onSuccess: (response) => {
        setTaxList(
          response.data.map((tax: any) => ({
            label: tax.chargeTypeName,
            value: tax.chargeType,
          }))
        );
      },
    }
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tableData",
  });

  const { isLoading, data: response } = useQuery(
    ["vendorSearch", { PayeeType: getInitialPayeeType() }],
    () =>
      searchVendor({
        PayeeType: getInitialPayeeType(),
        Page: 1,
        PageSize: 4000,
        Status: 1,
      }),
    { enabled: true, retry: false }
  );

  const vendorOptions =
    response?.data?.items?.map((vendor: VendorDetailType) => ({
      label: vendor.name,
      value: vendor.payeeId,
    })) || [];

  const recalculateTotals = () => {
    // Total calculation logic
  };

  const addRow = () => {
    append({
      payeeId: { value: "" },
      amountPayable: 0,
      vat: false,
      whtGeneralContract: false,
      whtConsultancyService: false,
      stampDuty: false,
      reason: "",
      supportingDocument: "",
      amount: 0,
    });
  };

  const ensureDecimals = (num: any, decimalPlaces = 2) => {
    const parts = num.toString().split(".");
    if (parts.length === 1) {
      return num.toFixed(decimalPlaces); // If no decimal part, return the fixed number
    }
    const integerPart = parts[0];
    let decimalPart = parts[1].substring(0, decimalPlaces); // Take the first n decimal digits
    if (decimalPart.length < decimalPlaces) {
      decimalPart = decimalPart.padEnd(decimalPlaces, "0"); // If not enough decimal places, pad with zeros
    }
    return `${integerPart}.${decimalPart}`;
  };
  

  const onSubmit = async (data: any) => {
    //@ts-ignore
   const duplicateResult = findDuplicatePayees(generalTableData);

  if (duplicateResult) {
    const { id: duplicatePayeeId, indices: duplicateIndices } = duplicateResult;

    console.log(duplicateIndices, "Duplicate Payee Indices");

    // Scroll to the first duplicate entry
    const element = document.getElementById(`row-${duplicateIndices[0]}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    // Show toast message
    toast.error('Duplicate payee found. Please update the duplicate entry before continuing.');
    setShowInitiateModal(false);
    return;
  }
    const payouts = generalTableData?.map((item) => {
      let documents = []; //@ts-ignore

      if (item.supportingDocument && item.supportingDocument?.length > 0) {
        //@ts-ignore

        const fileType = item.fileName ? item.fileName.split(".").pop().toLowerCase()
          : "txt";

        const mimeType =
          fileType === "pdf"
            ? "application/pdf"
            : fileType === "csv"
            ? "text/csv"
            : `image/${fileType}`; //@ts-ignore

        documents = item.supportingDocument.map(
          (doc: string) => `data:${mimeType};base64,${doc}`
        );
      } // Initialize charges and taxPayable

      let charges: chargeDataPayloadType[] = [];

let totalTax = 0; // Helper to add charge if applicable

const addCharge = (condition: boolean, tax: TaxItemType) => {
  if (condition) {
    const calculatedCharge = item.amount * parseFloat(tax?.chargeLogic);

    const formattedCharge = ensureDecimals(calculatedCharge, tax.decimalPlaces);

    totalTax += parseFloat(formattedCharge);

    charges.push({
      chargeType: tax.chargeType,
      chargePercentage: tax.chargePercentage.toString(),
      calculatedCharge: formattedCharge, // Use the correctly calculated charge
    });
  }
}; // Apply each tax condition


      payeeType === "Vendor" &&
        item?.vat &&
        addCharge(
          item?.vat,

          taxData?.data.find((tax: TaxItemType) => tax.chargeType === 1)
        );

      payeeType === "Vendor" &&
        item?.whtGeneralContract &&
        addCharge(
          item?.whtGeneralContract,

          taxData?.data.find((tax: TaxItemType) => tax.chargeType === 2)
        );

      payeeType === "Vendor" &&
        item?.whtConsultancyService &&
        addCharge(
          item?.whtConsultancyService,

          taxData?.data.find((tax: TaxItemType) => tax.chargeType === 3)
        );

      payeeType === "Vendor" &&
        item?.stampDuty &&
        addCharge(
          item?.stampDuty,

          taxData?.data.find((tax: TaxItemType) => tax.chargeType === 4)
        );

      return {
        payeeId: item.payeeId?.value,

        amount: ensureDecimals(item.amount),

        reason: item.reason,

        documents: documents,

        taxPayable: ensureDecimals(totalTax),

        charges: payeeType === "Vendor" ? charges : [],
      };
    });

    const currentPayload = {
      payeeType: payeeType === "Vendor" ? 1 : 2,

      payouts: payouts,
    };
console.log(currentPayload, "payload");

    setPayload(currentPayload);

    mutateAsync(currentPayload);
  };

  const { mutate: mutateAsync, isLoading: isSubmitingData } = useMutation(
    intiateBulkPayout,
    {
      onSuccess: (response) => {
        setStatus("payoutSuccessful");
        setSuccessMessage(response.message); // toast.success("Payout initiated successfully!");
        setShowInitiateModal(false); // Close the modal on success
        resetDuplicateIndex(); // Add this line
      },
      onError: (error: AxiosError<{ message: string }>) => {
        if (error?.response) {
          setStatus("payoutFailed");
          setErrorMessage(error?.response?.data?.message); // toast.error( //   `Failed to initiate payout: ${error?.response?.data?.message}` // );
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      },
    }
  );

  const resetDuplicateIndex = () => {
    setDuplicateIndex(null);
  };

  const handleInitiateProcess = () => {
    setShowInitiateModal(true);
  };

  const closePayoutProcess = () => {
    setClosePayout(true);
  };

  const getSelectedTaxes = (rowNumber: number) => {
    const list = [];

    if (generalTableData && generalTableData[rowNumber]?.stampDuty)
      list.push(4);
    if (generalTableData && generalTableData[rowNumber]?.vat) list.push(1);
    if (generalTableData && generalTableData[rowNumber]?.whtGeneralContract)
      list.push(2);
    if (generalTableData && generalTableData[rowNumber]?.whtConsultancyService)
      list.push(3);

    return list;
  };

  const getTotalChargeByType = (chargeType: number) => {
    const tax = taxData?.data.find(
      (taxSample: TaxItemType) => taxSample?.chargeType === chargeType
    );
    if (!tax) return 0;

    let totalCharge = 0;

    generalTableData?.forEach((item) => {
      if (
        (chargeType === 1 && item.vat) ||
        (chargeType === 2 && item.whtGeneralContract) ||
        (chargeType === 3 && item.whtConsultancyService) ||
        (chargeType === 4 && item.stampDuty)
      ) {
        totalCharge +=
          parseFloat(item?.amount?.toString()) * parseFloat(tax.chargeLogic);
      }
    });

    return totalCharge;
  };
  // Calculate totals for each charge type
  const totalVat =
    isRetrievingTaxList || !generalTableData ? 0 : getTotalChargeByType(1);
  const totalStampDuty =
    isRetrievingTaxList || !generalTableData ? 0 : getTotalChargeByType(4);
  const totalWhtConsultancyService =
    isRetrievingTaxList || !generalTableData ? 0 : getTotalChargeByType(3);
  const totalWhtGeneralContract =
    isRetrievingTaxList || !generalTableData ? 0 : getTotalChargeByType(2);

  const calculateRowAmountPayable = (
    amount: number,
    selectedTaxes: number[]
  ) => {
    let taxDeduction = 0;
    taxData?.data?.forEach((tax: TaxItemType) => {
      //@ts-ignore
      if (selectedTaxes?.includes(tax.chargeType)) {
        taxDeduction += amount * parseFloat(tax.chargeLogic);
      }
      console.log(taxDeduction, "taxDeduction");
    });
    return Number((amount - taxDeduction).toFixed(2));
  };

  // Handle Tax
  const calculateAmountPayable = (
    baseAmount: number,
    selectedTaxes: number[]
  ) => {
    let totalAmount = baseAmount;

    selectedTaxes.forEach((taxId) => {
      const tax = taxData?.data?.find((t: any) => t.chargeType === taxId);
      if (tax) {
        totalAmount -= (baseAmount * parseFloat(tax.chargeLogic)) / 100;
      }
    });

    return totalAmount;
  };

  // Handle selected tax
  const handleTaxChange = (
    rowIndex: number,
    taxType: string,
    isChecked: boolean
  ) => {
    //@ts-ignore
    setValue(`tableData[${rowIndex}].${taxType}`, isChecked);

    // Retrieve the updated list of selected taxes for the row
    const allSelectedTaxes = getSelectedTaxes(rowIndex);

    // Get the amount for the current row
    //@ts-ignore
    const amount = parseFloat(watch(`tableData[${rowIndex}].amount`) || "0");

    // Calculate the new amount payable based on the selected taxes
    const amountPayable = calculateRowAmountPayable(amount, allSelectedTaxes);

    // Update the row's amount payable
    //@ts-ignore
    setValue(`tableData[${rowIndex}].amountPayable`, amountPayable);

    // Recalculate the totals
    calculateTotals();
  };

  const calculateTotals = () => {
    const tableData = watch("tableData");
    let newTotalExcludingTax = 0;
    let newTotalTaxDeduction = 0;
    let newTotalAmountPayable = 0;

    tableData?.forEach((row: any) => {
      newTotalExcludingTax += parseFloat(row.amount || 0);
      newTotalAmountPayable += parseFloat(row.amountPayable || 0);
    });

    newTotalTaxDeduction = newTotalExcludingTax - newTotalAmountPayable;

    setTotalTaxDeduction(newTotalTaxDeduction);
    setTotalAmountPayable(newTotalAmountPayable);
  };

  const renderCheckboxGroup = (rowIndex: number, taxType: string) => {
    const filteredTaxList = taxList.filter((tax: any) => {
      switch (taxType) {
        case "vat":
          return tax.label.startsWith("VAT");
        case "whtGeneralContract":
          return tax.label.includes("WHT") && tax.label.includes("5%");
        case "whtConsultancyService":
          return tax.label.includes("WHT") && tax.label.includes("10%");
        case "stampDuty":
          return tax.label.includes("Stamp Duty");
        default:
          return false;
      }
    });

    //@ts-ignore
    const isChecked = watch(`tableData[${rowIndex}].${taxType}`);

    return (
      <>
      <Checkbox.Group
        options={filteredTaxList}
        // value={isChecked ? [taxType] : []} // Reflect the current checked state
        onChange={(checkedValues) => {
          const isChecked = checkedValues.length > 0;
          handleTaxChange(rowIndex, taxType, isChecked);
        }}
      />
      {taxType === "vat" && (
  <span className="text-[#FF3B30]">
    {errors?.tableData?.[rowIndex]?.root?.message ?? ""}
  </span>
)}
      </>
    );
  };

  const renderIconAndStatus: any = useMemo(() => {
    switch (status) {
      case "payoutSuccessful":
        return {
          icon: (
            <>
              <CheckMarkIcon />
              <GradientBackground />
              {" "}
            </>
          ),
          status: "success",
          title: "Payout initiated",
          description:
            "An approver will be required to approve this action before the payout is completed",
        };
      case "connectionError":
        return {
          icon: <ConnectionErrorIcon />,
          status: "warning",
          title: "Connection Error",
          description:
            "Sorry, we lost connection. Your computer may be offline or we may be experiencing some problemsWe will automatically try to reconnect",
          showBackButton: true,
          actionText: "Retry",
          onRetry: () => {
            mutateAsync(payload);
          },
        };
      default:
        return {
          icon: <ErrorIcon />,
          status: "error",
          title: "Action Failed",
          description:
            errorMessage ||
            "An error occurred while trying to initiate this payout",
          showBackButton: true,
          actionText: "Retry",
          onRetry: () => {
            mutateAsync(payload);
          },
        };
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, successMessage, payload, status]);


  const validateDuplicatePayees = (tableData: any[]) => {
    if (!tableData) return null;
  
    const payeeIds = tableData.map((row) => row.payeeId?.label);
    const duplicates = payeeIds.filter(
      (label, index, arr) => arr.indexOf(label) !== index && label !== undefined
    );
  
    if (duplicates.length > 0) {
      return `Duplicate Payees found: ${Array.from(new Set(duplicates)).join(", ")}`;
    }
  
    return null; // No duplicates found
  };

  const findDuplicatePayees = (data: any[]) => {
    const payeeIds = data.map(item => item.payeeId?.value);
    const duplicates: Record<string, number[]> = {};
  
    // Track indices of duplicates
    payeeIds.forEach((id, index) => {
      if (!id) return;
      if (duplicates[id]) {
        duplicates[id].push(index);
      } else {
        duplicates[id] = [index];
      }
    });
  
    // Return the first duplicate and its indices
    for (const [id, indices] of Object.entries(duplicates)) {
      if (indices.length > 1) {
        return { id, indices };
      }
    }
    return null;
  };
  
  
  useEffect(() => {
    setFilter((prevFilters) => ({
      ...prevFilters,
      PayeeType: getInitialPayeeType(),
    }));
  }, [payeeType]);

  useEffect(() => {
    console.log(fields, "tableInput");
  }, [fields]);

  useEffect(() => {
    calculateTotals();
  }, [generalTableData]);
  
  useEffect(() => {
    const tableData = watch("tableData");
    if (tableData) {
      const errorMessage = validateDuplicatePayees(tableData);
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }, [watch("tableData")]);

useEffect(() => {
console.log("Form errors:", errors);
}, [errors]);

useEffect(() => {
  if (errors && Object.keys(errors).length > 0) {
    console.log("Form errors detected:", errors);
    setShowInitiateModal(false);
  }
}, [errors]);

const resetRowTaxes = (rowIndex: number) => {
  const taxTypes = ["vat", "whtGeneralContract", "whtConsultancyService", "stampDuty"]; // Add other tax types if necessary

  taxTypes.forEach((taxType) => {
    //@ts-ignore
    setValue(`tableData[${rowIndex}].${taxType}`, false);
  });
};



  console.log(generalTableData, "generalTableData");

  return (
    <BulkPayoutLayout
    pageHeading="New Bulk Payment"
    additionalClassName="manual-payout-container"
    topCancelButtonAction={() => navigate("/bulk-payout")}
    continueButtonAction={handleInitiateProcess}
    bottomCancelButtonAction={closePayoutProcess}
    showButtomButtons={status === ""}>
    {statuses.includes(status) ? (
    <StatusContainer
    heading={renderIconAndStatus?.title}
    buttonText={renderIconAndStatus?.actionText}
    description={renderIconAndStatus?.description}
    children={renderIconAndStatus?.children}
    status={renderIconAndStatus?.status}
    setOpenDrawer={() => navigate("/bulk-payout")}
   closeFunction={() => {
    navigate("/bulk-payout");
  }}
    CustomBackAction={() => setStatus("")}
    icon={renderIconAndStatus?.icon}
    showBackButton={renderIconAndStatus?.showBackButton}
    onRetry={renderIconAndStatus?.onRetry}
    />
    ) : (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-10">
          <div className="w-[267px] text-[#0d0d0d] text-2xl font-semibold font-['Archivo'] leading-[28.80px]">
            Initiate a payment for your {payeeType}s
          </div>
          <div className="mt-8 main-table-wrapper">
            <DynamicTable
              tableData={fields}
              fields={[
                { name: "vendorName", displayName: "Name" },
                { name: "amount", displayName: "Amount" },
                ...(payeeType === "Vendor"
                  ? [
                      { name: "vat", displayName: "VAT (7.5%)" },
                      { name: "whtGeneralContract", displayName: "WHT (5%)" },
                      {
                        name: "whtConsultancyService",
                        displayName: "WHT (10%)",
                      },
                      { name: "stampDuty", displayName: "Stamp duty (1%)" },
                    ]
                  : []),
                { name: "reason", displayName: "Reason" },
                { name: "document", displayName: "Document" },
                { name: "amountPayable", displayName: "Amount payable" },
                { name: "actions", displayName: "Actions" },
              ]}
              builder={(field, item, rowIndex, columnIndex) => {
                switch (field.name) {
                  case "vendorName":
                    return (
                      <div className="w-[200px] overflow-visible z-50">
                        <CustomInputDropdown
                          name={`tableData[${rowIndex}].payeeId`}
                          dataItems={vendorOptions}
                          label=""
                          isSearchable={true}
                          isClearable={true}
                          control={control}
                          errorMessage={
                            errors?.tableData?.[rowIndex]?.payeeId?.value
                              ?.message
                          }
                          // errorMessage={
                          //   errors?.tableData?.[rowIndex]?.payeeId?.value?.message ||
                          //   (errors?.tableData?.message && "Duplicate payee detected.")
                          // }
                          menuPortalTarget={document.body}
                          //@ts-ignore
                          isHighlighted={duplicateIndex === `tableData[${rowIndex}].payeeId`}                        />
                      </div>
                    );
                  case "amount":
                    return (
                      <div className="w-[100px]">
                        <CustomInput
                          type="number"
                          control={control}
                          name={`tableData[${rowIndex}].amount`}
                          placeholder=""
                          //@ts-ignore
                          handleOnChange={(value) => {
                            const amount = parseFloat(value);
                            // If the amount is cleared (empty or 0), reset selected taxes and amountPayable
                        if (amount === 0) {
                       resetRowTaxes(rowIndex);
                       //@ts-ignore
                      setValue(`tableData[${rowIndex}].amountPayable`, 0);
                        } else {
                     // Otherwise, calculate the amountPayable with selected taxes
                     const allSelectedTaxes = getSelectedTaxes(rowIndex);
                     const amountPayable = calculateRowAmountPayable(amount, allSelectedTaxes);
                                            //@ts-ignore
                     setValue(`tableData[${rowIndex}].amountPayable`, amountPayable);
                      }

                   // Recalculate totals after changes
                    calculateTotals();
                         }}
                          errorMessage={
                            errors?.tableData?.[rowIndex]?.amount?.message ?? ""
                          }
                          onFocus={(e) => {
                            if (e.target.value === "0") {
                              e.target.value = "";
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value === "") {
                              e.target.value = "0";
                            }
                          }}
                        />
                      </div>
                    );
                  case "vat":
                    return renderCheckboxGroup(rowIndex, "vat");
                  case "whtGeneralContract":
                    return renderCheckboxGroup(rowIndex, "whtGeneralContract");
                  case "whtConsultancyService":
                    return renderCheckboxGroup(
                      rowIndex,
                      "whtConsultancyService"
                    );
                  case "stampDuty":
                    return renderCheckboxGroup(rowIndex, "stampDuty");
                  case "reason":
                    return (
                      <div className="w-[200px]">
                        <CustomInput
                          name={`tableData[${rowIndex}].reason`}
                          type="text"
                          control={control}
                          placeholder="Reason"
                          errorMessage={
                            errors?.tableData?.[rowIndex]?.reason?.message ?? ""
                          }
                        />
                      </div>
                    );
                  case "document":
                    const handleFileUploadChange: UploadProps["onChange"] = (
                      info
                    ) => {
                      if (
                        info.file.status === "done" ||
                        info.file.status === "uploading"
                      ) {
                        toast.success(
                          `${info.file.name} file uploaded successfully`
                        );
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          const base64 = e.target?.result as string;
                          const base64Data = base64.split(",")[1];
                          setValue(`tableData.${rowIndex}.supportingDocument`, [
                            base64Data,
                          ]);
                          //@ts-ignore
                          setValue(`tableData.${rowIndex}.fileName`,
                            info.file.name
                          );
                          //@ts-ignore
                          setValue(`tableData.${rowIndex}.fileUploaded`, true);
                        };
                        reader.readAsDataURL(info.file.originFileObj as Blob);
                      } else if (info.file.status === "error") {
                        // toast.error(`${info.file.name} file upload failed.`);
                      }
                    };

                    const handleRemoveFile = () => {
                      setValue(`tableData.${rowIndex}.supportingDocument`, []);
                      //@ts-ignore
                      setValue(`tableData.${rowIndex}.fileName`, null);
                      //@ts-ignore
                      setValue(`tableData.${rowIndex}.fileUploaded`, false);
                    };
                    //@ts-ignore
                    const fileName: string | null = watch(`tableData.${rowIndex}.fileName`
                    );
                    //@ts-ignore
                    const fileUploaded: boolean = watch(`tableData.${rowIndex}.fileUploaded`) || false;

                    return (
                      <div>
                        {fileUploaded && fileName ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span style={{ marginRight: "8px" }}>
                              {fileName}
                            </span>
                            <DeleteIcon
                              onClick={handleRemoveFile}
                              style={{
                                cursor: "pointer",
                                fontSize: "16px",
                                color: "#5f38fa",
                              }}
                              className="fill-[#0D0D0D]"
                            />
                          </div>
                        ) : (
                          <Upload
                            maxCount={1}
                            accept=".csv,.pdf"
                            action="" // Prevents default upload to localhost
                            showUploadList={false} // Hide default Ant Design upload list
                            beforeUpload={(file) => {
                              const isLt2M = file.size / 1024 / 1024 < 2;
                              if (!isLt2M) {
                                toast.error("File must be smaller than 2MB!");
                              }
                              return isLt2M; // Only allow upload if file is under 2MB
                            }}
                            onChange={handleFileUploadChange}
                          >
                            <Button>Add Document</Button>
                          </Upload>
                        )}
                      </div>
                    );
                  // ... (Keep the existing document case logic)
                  case "amountPayable":
                    return (
                      <CustomInput
                        isDisabled
                        type="number"
                        name={`tableData[${rowIndex}].amountPayable`}
                        control={control}
                        placeholder=""
                      />
                    );
                  case "actions":
                    return (
                      <DeleteIcon
                        className="fill-[#0D0D0D]"
                        onClick={() => remove(rowIndex)}
                        style={{ color: "#5f38fa", cursor: "pointer" }}
                      />
                    );
                  default:
                    return null;
                }
              }}
              rows={fields.length}
              // columns={fields.length}
            />
          </div>
          <div
            className="bg-[#f0f0f0] p-2"
            onClick={addRow}
            style={{
              cursor: "pointer",
              color: "#8c8c8c",
              fontWeight: "bold",
              textAlign: "center",
              margin: "16px 0",
            }}
          >
            <PlusOutlined /> Add item
          </div>
          <hr className="border-dashed my-4" />
          <div className="w-full flex justify-end pb-5 border-b">
            <div className="w-full md:w-[550px]">
              <p className="tax-info">
                <span>Total excluding tax</span>
                <span>{formatCurrency(totalExcludingTax || 0)}</span>
              </p>
              {taxData?.data?.map((tax: TaxItemType) => {
                const taxAmount = selectedTaxes[tax.chargeType] || 0;
                return taxAmount > 0 ? (
                  <p className="tax-info" key={tax.chargeType}>
                    <span>
                      {tax.chargeTypeName} ({tax.chargePercentage}%)
                    </span>
                    <span>-{formatCurrency(taxAmount)}</span>
                  </p>
                ) : null;
              })}
              {payeeType === "Vendor" &&
                generalTableData &&
                generalTableData?.filter((item) => item.vat === true).length >
                  0 && (
                  <p className="flex justify-between text-[#0D0D0D99] font-medium text-sm">
                    <span>VAT (7.5%)</span>
                    <span>{formatCurrency(totalVat || 0)}</span>
                  </p>
                )}
              {payeeType === "Vendor" &&
                generalTableData &&
                generalTableData?.filter(
                  (item) => item.whtGeneralContract === true
                ).length > 0 && (
                  <p className="flex justify-between text-[#0D0D0D99] font-medium text-sm">
                    <span>WTH (5%)</span>
                    <span>{formatCurrency(totalWhtGeneralContract || 0)}</span>
                  </p>
                )}
              {payeeType === "Vendor" &&
                generalTableData &&
                generalTableData?.filter(
                  (item) => item.whtConsultancyService === true
                ).length > 0 && (
                  <p className="flex justify-between text-[#0D0D0D99] font-medium text-sm">
                    <span>WTH (10%)</span>
                    <span>
                      {formatCurrency(totalWhtConsultancyService || 0)}
                    </span>
                  </p>
                )}
              {payeeType === "Vendor" &&
                generalTableData &&
                generalTableData?.filter((item) => item.stampDuty === true)
                  .length > 0 && (
                  <p className="flex justify-between text-[#0D0D0D99] font-medium text-sm">
                    <span>Stamp duty (1%)</span>
                    <span>{formatCurrency(totalStampDuty || 0)}</span>
                  </p>
                )}
              <hr className=" border-dashed my-4" />
              <p className="flex justify-between text-[#0D0D0D] text-xl font-medium">
                <span>Amount payable</span>{" "}
                <span>{formatCurrency(generalTotalamountPayable || 0)}</span>
              </p>
            </div>
          </div>
          {showInitiateModal && (
            <Modal
              title={<p className="text-2xl font-semibold">Initiate Payout?</p>}
              open={showInitiateModal}
              onCancel={() => {
                setShowInitiateModal(false);
              }}
              onOk={() => setShowInitiateModal(false)}
              footer={null}
            >
              <p className="text-[#0D0D0D99] mt-6 mb-10">
                Are you sure you want to initiate this payout? You won’t be able
                to make any changes once you do so. Please review all details
                before proceeding
              </p>
              <div className="flex justify-end gap-3 items-center">
                <OutlineButton
                  onClick={() => {
                    setShowInitiateModal(false);
                  }}
                  label={"Cancel"}
                  className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block"
                />
                <PrimaryButton
                  onClick={handleSubmit(onSubmit)}
                  label={isSubmitingData ? "Submitting..." : "Payout"}
                  className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block  !text-white focus:shadow-none"
                  disabled={isSubmitingData}
                />
              </div>
            </Modal>
          )}

          {closePayout && (
            <Modal
              title={<p className="text-2xl font-semibold">Close Payout?</p>}
              open={closePayout}
              onCancel={() => {
                setClosePayout(false);
              }}
              onOk={() => setClosePayout(false)}
              footer={null}
            >
              <p className="text-[#0D0D0D99] mt-6 mb-10">
                You will lose all items entered on this payment if you choose to
                close now
              </p>
              <div className="flex justify-end gap-3 items-center">
                <OutlineButton
                  onClick={() => {
                    setClosePayout(false);
                  }}
                  label={"Cancel"}
                  className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block"
                />
                <PrimaryButton
                  onClick={() => navigate("/initiate-bulk-payout")}
                  label={"Close Payout"}
                  className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block  !text-white focus:shadow-none"
                />
              </div>
            </Modal>
          )}
        </div>
      </form>
          )}

    </BulkPayoutLayout>
  );
};

export default BulkPayoutManualEntry;
